@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap");
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
} /* width */
::-webkit-scrollbar {
  width: 15px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dc3545;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  overflow-x: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.home-cover {
  display: none;
}
@media screen and (min-width: 769px) {
  .home-cover {
    display: block;
  }
}
@media screen and(max-width:769px) {
  .cont-price,
  .body {
    margin-top: 30px !important;
  }
  body {
    font-size: 16px;
  }
}
