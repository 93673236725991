$base-color: #3b2b98;
$change-color: #dc3545;
.trigger {
  color: $base-color;
  float: right;
  z-index: 10;
  font-size: 30px;
  margin: 2px 2px 5px;
  padding: 10px;
  animation: twistInLeft 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.container-nav {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 10;
}
.trigger-div {
  display: flex;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
}
.activetrue li {
  cursor: pointer;
}

@media (max-width: 769px) {
  .body,
  .cont-price {
    margin-left: 1%;
    margin-right: 1%;
  }
  .cont-price {
    width: 250px;
    padding: 15px;
    margin: auto;
  }
  .h-line {
    width: 200px !important;
  }
  .activetrue {
    left: 0px;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin: auto;
    direction: rtl;
    display: flex;
    list-style: none;
    color: $base-color;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 5;
    width: 95%;
    height: 100%;
    animation: backInRight 1s;
    animation-timing-function: ease-in-out;
    font-size: 25px;
    font-weight: 700;
    li {
      display: flex;
      align-items: baseline;
    }
  }
  .home-cover {
    display: none;
  }
  #home {
    margin-top: 10rem;
  }
  .navall,
  .activefalse {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .container {
    max-width: 100%;
  }
  header {
    display: none;
  }
}
@keyframes twistInLeft {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(1) translateX(-100%);
  }

  50% {
    opacity: 1;
    transform: translateY(0px) rotate(-360deg) scale(1);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) rotate(0deg) scale(1);
  }
}
@keyframes backInRight {
  0% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
