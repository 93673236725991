$base-color: #3b2b98;
$change-color: #dc3545;
$c-width: 1000px;

html {
  overflow-x: hidden;
  direction: rtl;
}
@media screen and(max-width:400px) {
  body {
    font-size: 14px !important;
  }
  .header .lang .line {
    margin: 0px 5px !important;
  }
}
@media screen and(min-width:250px) and(max-width:400px) {
  .header {
    font-size: 12px;
    height: 25px !important;
  }
  .cont-price {
    width: 200px !important;
    min-width: 200px !important;
  }
}
@media screen and(min-width:768px) and(max-width:955px) {
  .logo {
    flex: 34% !important;
  }
  .nav-ul {
    flex: 66% !important;
  }
}

@media screen and(max-width:550px) {
  .cont-price {
    width: 450px !important;
    min-width: 450px !important;
  }
}
@media screen and(max-width:450px) {
  .cont-price {
    width: 280px !important;
    min-width: 280px !important;
  }
}
@media screen and(max-width:700px) {
  .cont-price {
    min-width: 300px;
  }
  .container-contact {
    padding: 2% 0% 2% 0%;
  }
  .container-cards,
  .container-contact,
  .company {
    flex-wrap: wrap;
    align-items: center;
    .card-comp {
      flex: 90%;
    }
  }
  .company img {
    margin: 5%;
  }
}
.btn-lang {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.mobile-nav {
  display: none;
}
.envelope {
  width: 20px;
  height: 20px;
  margin-left: 1.5%;
}

.c-color {
  background-color: $base-color;
  width: 100%;
  color: #eeeeee;
  font-size: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.header {
  display: flex;
  justify-content: space-between;
  max-width: $c-width;
  margin: auto;
  height: 35px;
  align-items: center;
  a {
    text-decoration: none;
    color: #eeeeee;
  }
  .lang {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    .line {
      border-left: 1px solid #eeeeee;
      height: 29px;
      margin: 0px 15px;
    }
  }
  .email {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $change-color;
    padding-left: 2%;
    padding-right: 2%;
  }
  a {
    margin-left: 1%;
  }
}
/**
Navbar
**/
body {
  transition: all 0.5s ease-out;
}
.navall {
  z-index: 10;
  position: fixed;
  /* top: 27px; */
  background-color: white;
  width: 100%;
}

html:not([data-scroll="0"]) #home {
  margin-top: 7.5rem;
}
html:not([data-scroll="0"]) .nav-ul li a {
  box-shadow: none;
}
.nav {
  max-width: $c-width;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.logo {
  flex: 50%;

  display: flex;
}

.active a {
  box-shadow: 0px 8px 0px 0px $change-color;
}
.nav-ul {
  flex: 50%;
  list-style: none;
  display: flex;
  // direction: rtl;
  justify-content: space-between;
  margin: -1px;
  font-size: 17px !important;
  li a {
    margin-left: 1px;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    border-left: 1px solid #e4e4e4;
    padding: 12px;
    box-shadow: 0px 8px 0px 0px #e4e4e4;
    color: $base-color;
    text-decoration: none;
  }
  li:hover {
    cursor: pointer;
  }
}
.home-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-cover {
  margin-top: 1%;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
/******
  price
  
*****/
.cont-price {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  z-index: 2;
  background-color: $change-color;

  border-radius: 15px 15px 0px 0px;

  min-width: 550px;

  padding: 50px 20px;
  margin-top: -2%;

  min-height: 230px;
  .price-ul {
    list-style: none;
    color: white;
    font-weight: 700;
    display: flex;
    padding: 0px;
    justify-content: space-between;
    // direction: rtl;
    border: 1px solid #fff;
    text-align: center;
    align-items: center;
    height: 50px;
    margin-bottom: 5%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    li {
      border-left: 1px solid #fff;
      flex: 40%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .name-ex {
      color: black;
      background-color: #fff;
      flex: 20%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    li:last-child {
      border-left: none;
    }
  }
  .noteError {
    margin-bottom: 15% !important;
  }
  .note,
  .noteError {
    text-align: center;
    // direction: rtl;
    color: #fff;
    font-size: 15px;
    display: flex;
    margin: auto;
    flex-direction: column;
    a {
      text-decoration: underline;
      color: #fff;
      margin-top: 3%;
    }
  }
}
.currency {
  list-style: none;
  color: white;
  font-weight: 700;
  display: flex;
  padding: 0px;
  justify-content: space-between;
  // direction: rtl;
  text-align: center;
  align-items: center;
  height: 50px;
  margin-bottom: 5%;

  li {
    flex: 40%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .name-ex {
    flex: 20%;
    width: 100%;
    height: 100%;
  }
}
/******
Body 
  *******/

.body {
  align-items: center;
  padding-top: 400px;
  // direction: rtl;
  margin: auto;
  max-width: $c-width;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.h-line {
  border-bottom: 3px solid $base-color;
  height: 5px;
  width: 400px;
  text-align: center;
  margin: 30px 20px;
  box-shadow: 0px 9px 14px 0px #e4e4e4;
}
.head-body,
.head-ser {
  color: $base-color;
  font-size: 22px;
  font-weight: 700;
}
/*****
  cards

  ****/
.ser {
  align-items: center;
  padding-top: 6%;
  padding-bottom: 3%;
  // direction: rtl;
  margin: auto;
  max-width: $c-width;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.container-cards {
  // direction: rtl;
  display: flex;
  justify-content: space-between;

  min-height: 300px;
  align-items: stretch;

  .card-comp {
    padding: 3% 5%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: space-around;
    background-color: $base-color;
    align-items: center;
    text-align: center;

    .card-icon {
      background-color: #fff;
      margin: auto;
      border-radius: 50%;
      padding: 8%;
    }

    .card-body {
      line-height: 2em;
    }
  }
  .color {
    background-color: #dc3545 !important;
  }
}
.cards-back {
  background-color: #3b2b98;
}
/***********
  company
  *******/
.company {
  min-width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 88px;
  margin-top: 2%;
  img {
    margin-left: 3%;
  }
}

/***********
  contact
  *******/

.container-contact {
  background-color: #3b2b98;
  padding: 3% 0% 3% 0%;

  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding:0px
  }
}
.footerDivContainer {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  background-color: #3b2b98;
  padding-top: 3%;
  border-top: 5px solid red;
}
.contactUs {
  color: white;
  font-size: 22px;
  font-weight: 700;
}
.contactUsLine {
  border-bottom: 3px solid white;
  height: 5px;
  width: 400px;
  text-align: center;
  margin: 30px 20px;
}
.container-contact .contact {
  flex: 50%;
}
/************
  footer
  ****************/
.footer {
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 10px;
  color: #fff;
  background-color: $base-color;

  border-bottom: 5px solid red;
}
.ifram {
  flex: 50%;
  min-height: 300px;
}
/***************
  loader
  ***************/
.loader {
  display: block;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3b2b98;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*************
    social media
    ***************/

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
.Socials {
  position: fixed;
  right: 0%;
  z-index: 2222;
  /* right: -40px; */
  top: 30%;
  display: none;
}
.Socials ul{
padding:0px}
.text-center {
  text-align: center;
}

.Socials li {
  width: 46px;
  height: 46px;
  line-height: 45px;
  font-size: 32px;
  padding-top: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.Socials li:nth-child(1) {
  background: #2ed02e;
  color: #fff;
  border-top-left-radius: 8px;
}

.Socials li,
.dro ul,
.footer ul li {
  list-style: none;
}
.Socials li:nth-child(2) {
  background: #848484;
  color: #fff;
}

.Socials li:nth-child(3) {
  background: #0078d7;
  color: #fff;
}

.Socials li:nth-child(4) {
  background: #1bd741;
  color: #fff;
  border-bottom-left-radius: 8px;
}
